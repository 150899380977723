// dependencies
import React from "react";
import dynamic from "next/dynamic";
import * as Sentry from "@sentry/nextjs";

// components
import Stack from "@cloudspire/legacy-resources/src/components/Stack";
import UIContainer from "@cloudspire/legacy-resources/src/components/UIContainer";

// errors
import {
  BaseError,
  InternalServerError,
  NotFoundError,
} from "@cloudspire/legacy-resources/src/errors";

const componentLoader = {
  store: {},
  get(key) {
    if (!(key in componentLoader.store)) {
      componentLoader.store[key] = dynamic(() =>
        import(`@cloudspire/legacy-resources/src/containers/Error/${key}`)
      );
    }

    return componentLoader.store[key];
  },
};

const getComponent = ({ error }) => {
  return {
    componentName:
      "string" === typeof error.component ? error.component : "Error",
    props:
      "object" === typeof error.props && null !== error.props
        ? { message: error.message, ...error.props }
        : { message: error.message },
  };
};

const ErrorPage = (props) => {
  const { error } = props;

  const { componentName, props: errorProps } = getComponent({
    error,
  });

  const Error = componentLoader.get(componentName);

  return (
    <>
      <Stack marginTop={1} />

      <UIContainer>
        <Error {...errorProps} />
      </UIContainer>

      <Stack marginTop={3} />
    </>
  );
};

ErrorPage.getInitialProps = async (ctx) => {
  await Sentry.captureUnderscoreErrorException(ctx);

  const { err: error } = ctx;

  let newError;
  if (error instanceof BaseError) {
    newError = error;
  } else if (ctx.res && 404 === ctx.res.statusCode) {
    newError = new NotFoundError("", {});
  } else if (error instanceof Error) {
    newError = new InternalServerError(error.message);
  } else {
    newError = new InternalServerError("error.generic");
  }

  if (!process.browser) {
    ctx.res.statusCode = newError.code;
  }

  return {
    error: newError,
  };
};

export default ErrorPage;
